<template>
  <div class="footer py-4">
    <div class="row align-items-center justify-content-between menu-pages">
      <div class="col col-xl-auto">
        <router-link :to="{ name: 'HomePage' }">
          <img alt="..." width="235" height="52" ref="logoFooterOffscreen" />
        </router-link>
      </div>
      <div class="col-xl col-12 my-3 my-xl-0 px-0 px-md-3">
        <div class="row menu-pages justify-content-xl-center">
          <div class="col-auto">
            <router-link :to="{ name: 'CookiePolicy' }">Politică cookie</router-link>
          </div>
          <div class="col-auto px-0 px-0 px-sm-3">
            <img src="../../assets/images/icons/gray-dot-4x4.svg" alt="..." width="4" height="4" />
          </div>
          <div class="col-auto">
            <router-link :to="{ name: 'TermsAndConditions' }">Termeni și condiții</router-link>
          </div>
          <div class="col-auto px-0 px-sm-3">
            <img src="../../assets/images/icons/gray-dot-4x4.svg" alt="..." width="4" height="4" />
          </div>
          <div class="col-auto">
            <router-link :to="{ name: 'PrivacyPolicy' }">GDPR</router-link>
          </div>
        </div>
        <div class="row menu-pages align-items-center justify-content-xl-center mt-2">
          <div class="col-auto order-1 order-sm-0">
            <a href="https://anpc.ro/" rel="noopener noreferrer" target="_blank">
              <img src="../../assets/images/anpc.webp" alt="ANPC" width="117" height="28" />
            </a>
          </div>
          <div class="col-sm-auto order-0 order-sm-2 mb-2 mb-sm-0 mt-sm-0">
            <div class="text-button-02 fw-semibold text-neutral-06">
              Copyright © Delicitate, {{ new Date().getFullYear() }}
            </div>
          </div>
          <div class="col-auto order-1 order-sm-3">
            <a
              rel="noopener noreferrer"
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
              target="_blank"
            >
              <img src="../../assets/images/sol.webp" alt="ANPC" width="117" height="28" />
            </a>
          </div>
        </div>
      </div>
      <div class="col col-xl-auto">
        Developed by
        <a href="https://www.expertware.net/" class="text-neutral-05" rel="noopener noreferrer" target="_blank">
          <strong class="text-neutral-08">Expertware</strong></a
        >
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/

export default {
  name: 'Navbar',
  emits: ['logIn', 'logOut', 'signUp'],
  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.$refs.logoFooterOffscreen.src = require('../../../public/logo-delicitate.webp');
          observer.unobserve(entry.target);
        }
      });
    });
    // Start observing the offscreen image element
    observer.observe(this.$refs.logoFooterOffscreen);
  },
};
</script>

<style scoped>
.footer {
  border: 1px solid transparent;
  border-width: 1px 0px 0px 0px;
  border-image: linear-gradient(
    90deg,
    rgba(223, 210, 187, 0) 0%,
    rgba(223, 210, 187, 0.6) 9.37%,
    #bfa577 48.44%,
    rgba(223, 210, 187, 0.6) 90.1%,
    rgba(223, 210, 187, 0) 100%
  );
  border-image-slice: 1;
  min-height: 101px;
  position: relative;
  background-color: var(--neutral-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3%;
  padding-right: 3%;
  z-index: 1;
  border-top: 1px solid;
}

.menu-pages a:hover {
  color: var(--neutral-7);
}
ul {
  margin-bottom: 0px;
}
.menu-pages a {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: var(--neutral-6);
  transition: 0.2s;
}
@media only screen and (max-width: 576px) {
  .footer {
    padding-bottom: 100px !important;
  }
  .footer .row {
    margin: 0;
  }
}
@media only screen and (max-width: 768px) {
  .menu-pages {
    margin: 0;
  }
  .menu-pages .col-4 {
    text-align: center;
  }
}
/* .menu-pages li:not(:last-child):after {
  content: ' ';
  background-image: url('../../assets/images/icons/gray-dot-4x4.svg');
  display: block;
  background-size: 4px;
  background-repeat: no-repeat;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: -21px;
}
@media only screen and (min-width: 1200px) {
  .menu-pages li:not(:last-child):after {
    right: 10px;
  }
} */

.router-link-active {
  color: var(--primary-5) !important;
}
</style>
